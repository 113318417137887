<template>
  <div class="max-w-desktop mx-auto px-6">
    <LogoHeader />
    <div class="mx-auto flex flex-grow flex-col w-full">
      <h2 class="capitalize mx-auto flex justify-center">
        Please complete your Profile
      </h2>

      <form class="h-full flex flex-col" @submit.prevent="updateUserProfile()">
        <InputWrapper
          v-if="showDateOfBirthInput()"
          v-model="dateOfBirth"
          class="mt-4"
          name="dateOfBirth"
          :label="$t('message.date-of-birth')"
          type="date"
          :error="hasError('dateOfBirth')"
          :touched="touched('dateOfBirth')"
          :hint="hint('dateOfBirth')"
          @input="validateForm()"
        />
        <div class="font-body">
          {{ $t("message.must-be-at-least", { minAge: 12 }) }}.
        </div>

        <div v-if="showContactPreferences()" class="py-8">
          <label for="name" class="block font-bold mb-4">
            {{ $t("message.contact-preferences.heading") }}
          </label>
          <p class="text-sm mb-6">
            {{ $t("message.contact-preferences.subtext-1") }}
          </p>
          <p class="text-sm mb-6">
            {{ $t("message.contact-preferences.subtext-2") }}
          </p>

          <div class="flex">
            <Checkbox
              id="team-updates"
              v-model="contactPreferences"
              :trueValue="$system_config.teamUpdatesYes"
              :falseValue="$system_config.teamUpdatesNo"
            />
            <label class="font-body ml-4" for="team-updates">
              {{ $t("message.contact-preferences.team-updates") }}
            </label>
          </div>
          <div class="flex mt-4">
            <Checkbox
              id="recommended-content"
              v-model="contactPreferences"
              :trueValue="$system_config.recommendedContentYes"
              :falseValue="$system_config.recommendedContentNo"
            />
            <label class="font-body ml-4" for="recommended-content">
              {{ $t("message.contact-preferences.recommended-content") }}
            </label>
          </div>
          <div class="flex mt-4">
            <Checkbox
              id="marketing-content"
              v-model="contactPreferences"
              :trueValue="$system_config.marketingContentYes"
              :falseValue="$system_config.marketingContentNo"
            />
            <label class="font-body ml-4" for="marketing-content">
              {{ $t("message.contact-preferences.marketing-content") }}
            </label>
          </div>
        </div>

        <div v-if="errorMessage" class="text-red text-center w-full my-4">
          {{ errorMessage }}
        </div>
        <div class="flex flex-grow items-end">
          <button
            type="submit"
            class="mx-auto btn btn-lg bg-blue-900 focus:outline-none text-white border border-blue-900 outline-none flex text-center justify-center items-center px-6 my-4 mb-8"
          >
            <span class="mx-auto" v-if="!loading">
              {{ $t("message.continue") }}
            </span>
            <div class="loading w-6 h-6" v-else>
              <Spinner class="sm-button" />
            </div>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import LogoHeader from "@/components/shared/LogoHeader.vue";
import { PROFILE_BASIC_MUTATION } from "@/graphql/queries/user/userMutations.js";
import Checkbox from "@/components/form/Checkbox.vue";
import {
  isValidDate,
  dateIsAfter1900,
  isValidContactPreferencesAttribute,
  loadContactPreferencesAttribute
} from "@/utils/basicHelpers";
import { jwtTokenPayload } from "@/utils/cognitoAuthToken";
import InputWrapper from "@/components/form/InputWrapper.vue";
import Spinner from "@/components/shared/Spinner.vue";

export default {
  name: "IncompleteProfile",
  components: {
    LogoHeader,
    Spinner,
    Checkbox,
    InputWrapper
  },
  data() {
    return {
      dateOfBirth: "",
      requestDateOfBirth: true,
      contactPreferences: "",
      requestContactPreferences: true,
      loading: false,
      validation: {},
      showAllErrors: false,
      errorMessage: ""
    };
  },
  computed: {
    actualYear() {
      let date = new Date();
      return date.getFullYear();
    },
    touched() {
      return key => {
        return this.validation[key]?.touched == true;
      };
    },
    hasError() {
      return key => {
        return (
          (this.validation[key]?.error == true &&
            (this.showAllErrors == true ||
              this.validation[key]?.touched == true)) ||
          false
        );
      };
    },
    hint() {
      return key => {
        return this.validation[key]?.touched || this.showAllErrors
          ? this.validation[key]?.hint || ""
          : "";
      };
    }
  },
  async mounted() {
    const userAttributes = await jwtTokenPayload();
    if (userAttributes) {
      this.dateOfBirth = userAttributes["profile:birthdate"] || "";

      this.requestDateOfBirth = !isValidDate(this.dateOfBirth);
      this.contactPreferences = loadContactPreferencesAttribute(
        userAttributes["profile:contactPreferences"],
        preferences => preferences
      );
      this.requestContactPreferences = !isValidContactPreferencesAttribute(
        userAttributes["profile:contactPreferences"]
      );
      this.requestDateOfBirth = true;
      /** is this needed? I can see we are showing this based on tha validation of the values */
      this.requestContactPreferences = true;
    }
  },
  methods: {
    changed(key) {
      if (!this.validation[key]) {
        this.validation[key] = {
          touched: true
        };
      } else {
        if (this.validation[key].touched == null) {
          this.validation[key].touched = true;
        } else {
          this.validation[key] = { ...this.validation[key], touched: true };
        }
      }
      this.showAllErrors = true;
      this.validateForm();
    },
    addError(key, message) {
      if (this.validation[key]) {
        this.validation[key].error = true;
        this.validation[key].hint = message;
      } else {
        this.validation[key] = {
          hint: message,
          error: true
        };
      }
    },
    clearError(key) {
      if (this.validation[key]) {
        this.validation[key].error = false;
        this.validation[key].hint = "";
      }
    },
    resetForm() {
      this.validation = {};
    },
    validateForm() {
      this.showAllErrors = true;
      let errorCount = 0;
      if (this.requestDateOfBirth) {
        if (this.dateOfBirth == "") {
          this.addError("dateOfBirth", this.$t("message.formValidation.empty"));
          errorCount++;
        } else if (!isValidDate(this.dateOfBirth)) {
          this.addError(
            "dateOfBirth",
            this.$t("message.formValidation.invalid_date")
          );
          errorCount++;
        } else if (!dateIsAfter1900(this.dateOfBirth)) {
          this.addError(
            "dateOfBirth",
            this.$t("message.formValidation.invalid_date_of_birth")
          );
          errorCount++;
        } else {
          this.clearError("dateOfBirth");
        }
        // we don't include this validation here as they already have an account, we must let
        // them enter their under 12 date of birth and then use that to restrict their access.
        // else if(!dateIsOver12YearsAgo(this.dateOfBirth)) {
        //   this.validationErrors.push("You must be over 12 years old to use this website")
        // }
      }
      return errorCount === 0;
    },
    async updateUserProfile() {
      if (!this.validateForm()) {
        return;
      }

      this.loading = true;
      const newProfile = {};
      if (this.requestDateOfBirth) {
        newProfile.birthdate = this.dateOfBirth;
      }
      if (this.requestContactPreferences) {
        newProfile.contactPreferences = this.contactPreferences.join(",");
      }
      if (this.requestDateOfBirth || this.requestContactPreferences) {
        await this.$apollo
          .mutate({
            mutation: PROFILE_BASIC_MUTATION,
            variables: {
              newProfile
            },
            update: async () => {
              await this.$store.dispatch("auth/refreshAuthenticatedUser");
              this.$router.push(
                this.$route.params.redirect
                  ? { path: this.$route.params.redirect }
                  : { name: "HomeIn" }
              );
              this.loading = false;
              return true;
            }
          })
          .catch(err => {
            this.loading = false;
            console.error("update profile failed", err);
            this.$toast.open({
              type: "error",
              message: this.$t("message.request-failed"),
              position: this.$store.getters["general/getToastPosition"],
              duration: 0
            });
            return err;
          });
      } else {
        await this.$store.dispatch("auth/refreshAuthenticatedUser");
        this.$router.push(
          this.$route.params.redirect
            ? { path: this.$route.params.redirect }
            : { name: "HomeIn" }
        );
        this.loading = false;
      }
    },
    showDateOfBirthInput() {
      return this.requestDateOfBirth;
    },
    showContactPreferences() {
      return this.requestContactPreferences;
    }
  }
};
</script>
